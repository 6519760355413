.footer_container{
    width: 37rem;
    position: fixed;
    bottom: 0;
    z-index: 99;
    /* padding: 0 2.5rem 1rem 2.5rem; */
}
.footer_sub_container{
    width: 100%;
    display: grid;
    align-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 2rem 1rem 2rem 1rem;
    background-color:white;
    /* border-radius: 3rem; */
}

.tab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon{
    font-size: 3rem;
    color: grey;
}

.icon.active{
    color: #2B358A;
}

.bottom{
    height: 10px;
    background-color: #2B358A;
}


@media screen and (max-width:480px){
    .footer_container{
        width: 100%;
        border-radius: 0;
    }
}


