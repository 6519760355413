.data{
    position: absolute;
    top: 0;
    padding-top: 1.5rem;
    z-index: 99;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding-left: 0.5rem;
    gap:1rem;
}

 .p{
    color: white;
    font-size: 1.5rem;
    
  }
 .h3{
   
    color: white;
  }
  
.button{
    border-radius: 20px;
    border: none;
   width: 100%;
   height: 3rem;
   padding: 0.8rem;
    background-color: #E92230;
    color: white;
    
  }