

.main_container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
   
    padding: 2rem;
    padding-bottom: 10rem;
    z-index: 20;
}


.header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding: 2rem; */
    gap: 2rem;
    
}

.title{
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    color: #2B358A;
}


.search{
    background-color: rgb(214, 211, 211);
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
   
}

.search input{
    outline: none;
    border: none;
    background:none;
    width: 100%;

}

.icon{
   color: grey;
}

.games{
    background-color: white;
    border-radius: 20px;
}

.item{
    display: flex;
    padding: 2rem 0 2rem 0;
}

.star_icon{
    /* width: 10%; */
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image{
    width: 25%;
    /* padding: 1rem; */
}

.text{
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text p{
    font-size: 1.5rem;
    font-weight: bold;
    color: #2B358A;
}

.button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
}

.button button {
    width: 80%;
    border: none;
    background-color: rgb(199, 198, 198);
    color: #2B358A;
    height: 4rem;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 20px;
    padding:0.5rem 0 0.5rem 0 ;


}



.img{
    /* height: 100%; */
    width: 100%;
    height: 8rem;
}