.container{
    width: 100%;
    padding: 10rem 10px 15rem 10px;
    z-index: 99;
    background: transparent;
}

.unsubscribe_container{
    border: 0.3rem solid #FF465A;
    /* border: 0.3rem solid #73ee94; */
    width:100%;
    border-radius: 2rem;
    min-height: 20rem;
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    /* box-shadow: 0 0 0 4px #ff4659; */
}

.image_text_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.text_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.text{
    color: #172A6E;
    font-size: 2rem;
    font-family: 'Inter',sans-serif;
}

.p{
    color: #172A6E;
    font-size: 1.5rem;
    font-family: 'Inter',sans-serif;
}

.btn{
    width: 32rem;
    background-color: #2B358A;
    border: none;
    outline: none;
    height: 4.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.2rem;
    font-size: 2rem;
    color: white;
    font-weight: 700;
    font-family: 'Poppins',sans-serif;
    cursor: pointer;
}


.btn2{
    background-color: #D70A32;
    transition: all 0.2s;
}

.btn2.disabled{
    background-color: rgb(125, 125, 125);
    cursor: not-allowed;
}

.icon_container{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #26dc2f;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 4px #73ee94;
}

.icon_container_2{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #D70A32;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 4px #f04b6c;
}

.icon{
    color: #fff;
    font-size: 30px !important;
}