.container{
    padding: 2rem;
    /* display: flex; */
    width: 100%;
    padding-bottom: 15rem;
    padding-top: 0;
    z-index: 99;
}

.top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem;
}

.cat{
    font-size: 2rem;
    font-weight: bold;
    color: #2B358A;
    width: 50%;
}

.seeAll{
    color: #E92230;
    color: #E92230;
    width: 50%;
    text-align: end;
    font-size: 1rem;
}

.seeAll{
    color: #E92230;
    cursor: pointer;
}

.games{
    background-color: #E92230;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    border-radius: 20px;
    padding: 2rem;
    /* margin-top: 20px; */
}

.card{
    /* height: 20rem; */
    border-radius: 10px;
    height: 18rem;
    background-color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
    color: #2B358A;
    overflow: hidden;
    cursor: pointer;
    /* width: 6rem; */
}

.card img{
    /* width: 14rem; */
    width: 100%;
    height: 15rem;
    /* border-top-left-radius: 10px; */
    /* border-top-right-radius: 10px; */

    
}


@media only screen and (max-width:480px){
    /* .games{
      
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        border-radius: 20px;
        padding: 2rem;
        margin-top: 20px;
    } */
}