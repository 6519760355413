

.title_container{
   margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    z-index: 99;
}
.cartoon_image{
    width: 6rem;
    height: auto;
    object-fit: contain;
}
.name_image{
    width: 24rem;
    height: auto;
    object-fit: contain;
}

.tabs_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}
.tabs_sub_container{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    border-bottom: 0.1rem solid #E92230;
    cursor: pointer;
}
.tab_1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem 0 1rem;
    height: 100%;
    border-bottom: 0.2rem solid #E92230;

}
.tab_2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    height: 100%;
}

.tab_1 .tab{
    font-size: 2rem;
    font-weight: 800;
    color: rgb(23, 42, 110);
    font-family: 'Inter',sans-serif;
}

.tab_2 .tab{
    font-size: 2rem;
    font-family: 'Inter',sans-serif;
    color: rgb(23, 42, 110);
}

.form_container{
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 2rem 1rem 2rem;
}

.form{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 2rem;
}

.input{
    border: none;
    outline: none;
    background: transparent;
    width: 30rem;
    height: 5rem;
    color: #172A6E;
    font-size: 1.4rem;
    font-family: 'Inter',sans-serif;
    font-weight:500;
}

.input::placeholder{
    color: grey;
}

.input_group{
    width: 30rem;
    height: 5rem;
    background-color: rgba(168, 167, 167, 0.148);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
    border-radius: 0.4rem;
}

.country_code{
    color: grey;
    font-size: 2rem;
    font-weight: 800;
}

.selector{
    width: 25rem;
    font-size: 1.3rem !important;
    cursor: pointer;
}


.login_btn{
    width: 27rem;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    font-size: 2rem;
    font-family: 'Inter',sans-serif;
    font-weight: 900;
    color: white;
    background-color: #E92230;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    border: none;
    outline: none;
    border-radius: 1rem;
    box-shadow: 0 1rem 1rem rgba(0,0,0,0.3);
    cursor: pointer;
}

.footer_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem;
    /* position: fixed; */
    /* width: 37rem; */
    /* bottom: 10%; */
    z-index: 99;
    margin-top: 10rem;
}
.footer_sub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}
.footer_text{
    padding: 1rem 2rem 1rem 2rem;
    font-size: 1.4rem;
    font-family: 'Inter',sans-serif;
    color: white;
    text-align: center;
}

.top{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* text-align: center; */
    background-color: white;
    position: absolute;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    top: 0;
    height: 20px;
    width: 70%;
    align-self: center;
    
}


.dropdown_selector{
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.selector{
    width: 100%;
    padding: 15px 10px 15px 10px;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: #172A6E;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
}

.selected_value{
    font-size: 14px;
    font-family: 'Inter',sans-serif;
    color: #fafafa;
}

.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.line_breaker{
    width: 1px;
    height: 30px;
    background: #fafafa90;
}

.chevron_down{
    color: #fafafa90;
    display: inline-block; 
    border-right: 
            2px solid #fafafa90; 
    border-bottom: 
            2px solid #fafafa90; 
    width: 10px; 
    height: 10px; 
    transform: rotate(45deg); 
    margin-top: -5px;
}

.selector_options{
    width: 270px;
    background: #fff;
    box-shadow: 0 10px 30px rgba(0,0,0,0.2);
    border-radius: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}

.option{
    padding: 10px 10px 10px 10px;
    width: 100%;
}

.option.selected{
    background: #172A6E;
}

.option_text{
    font-size: 12px;
    color: #333333;
    font-family: 'Inter',sans-serif;
    font-weight: 400;
}

.option.selected .option_text{
    color: #fafafa;
}

.message_box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1.4rem;
    flex-direction: column;
    padding: 1rem 2rem 1rem 2rem;
    z-index: 99;
}

.wrapper_2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: -30px;
}

.message_primary{
    width:300px;
    text-align: center;
    color: #2A3382;
    font-size: 22px;
    font-weight: 900;
    font-family: 'Inter',sans-serif;
    padding: 0;
    margin: 0;
}

.message_secondary{
    width:300px;
    text-align: center;
    color: #2A3382;
    font-family: 'Inter',sans-serif;
    font-size: 22px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.switch_btn{
    width: 326px;
    padding: 10px 0 10px 0;
    background: #2B368A;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Inter',sans-serif;
    text-align: center;
    border-radius: 8px;
    color: #f1f1f1;
}

.stay_with_btn{
    width: 326px;
    padding: 10px 0 10px 0;
    background: #E92230;
    font-size: 20px;
    font-weight: 700;
    color: #f1f1f1;
    font-family: 'Inter',sans-serif;
    text-align: center;
    border-radius: 8px;
}

.back_btn{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #00063F;
    font-size: 2.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f1f1f1;
    box-shadow: 0 0 0 1px #333;
    align-self: flex-start;
    cursor: pointer;
}