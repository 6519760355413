.topLines{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    flex-direction: column;
    
}
.line1{
    overflow: hidden;
    width: 100%;
    height: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #172A6E;
}
