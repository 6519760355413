.container{
    width: 100%;
    padding: 1rem 2rem 1rem 2rem;
}
.sub_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.points_container{
    margin-top: 5rem;
    width: 20rem;
    position: relative;
    border: 3px solid #E92230;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background-color:  #E922300F;
    ;
    padding: 4rem 2rem 1rem 2rem;
}
.horse{
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 99;
}

.level{
    color: #172A6E;
    font-size: 2rem;
    font-weight: 400;
    font-family: 'Inter',sans-serif;
}

.percentage{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.points{
    color: #172A6E80;
    font-size: 1.5rem;
    font-family: 'Inter',sans-serif;
}

.levels_container{
    margin-top: 4rem;
}

.level_1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.level_1_circle{
    position: relative;
    width: 11rem;
    height: 11rem;
    border-radius: 50%;
    border:1rem solid #E92230;
    padding: 1rem;
    background-color: #95C11F26;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pencil{
    width: 6rem;
    height: auto;
    object-fit: contain;
}

.level_text{
    font-size: 2rem;
    font-family: 'Inter',sans-serif;
    color: #172A6E;
    font-weight: 400;
}

.image{
    position: absolute;
    bottom: 0rem;
    right: -1rem;
    width: 2.5rem;
}
.image .crown{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.span{
    position: absolute;
    bottom: 0.5rem;
    right: -2rem;
    width: 2.5rem;
    color: #172A6E;
    font-size: 1rem;
    font-family: 'Inter',sans-serif;
}

.levels_grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    align-items: center;
    align-content: center;
    gap: 2rem;
}