
.main{
    position: relative;
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    /* align-items: flex-start; */
    position: relative;
    top: 20%;
    gap: 2rem;
    background: linear-gradient(180deg, #FFFFFF 52.5%, #2B368A 100%);
    padding-top: 1rem;

 }

 .intro_main{
    position: relative;
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* align-items: center; */
    /* align-items: flex-start; */
    /*  */
    gap: 2rem;
    background:#2B368A;
    
 }

 .bg{
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     min-height: 100dvh;
  
 }
 .bg .image{
     width: 100%;
     opacity: 0.4;
     height: auto;
     object-fit: contain;
 }
 .white_line{
     position: absolute;
 }
 