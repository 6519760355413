.main{
    display: flex;
    justify-content: space-between;
    width: 100%;
    width: 37.5rem;
    color: #2B358A;
    z-index: 99;
}

.top_logo_container{
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo{
    display: flex;
    align-items: center;
}

.logo p{
    font-size: 2rem;
    color: #2B358A;
    font-weight: bold;
}

.menu_btn{
    border:2px solid #2B358A;
    border-radius: 20px;
    width: 8rem;
    height: 3rem;
    /* padding-left: 1rem; */
    /* padding-right: 1rem ; */
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.menu_icon{
    color: #2B358A;
}

.menu_btn p{
    color: #2B358A;
    font-size: 1.5rem;
    font-weight: bold;
}


.top_logo_image{
    width: 7rem;
    height: 4rem;
    object-fit: contain;
}


.SideBar{
    /* padding: 2rem; */
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
    color: #2B358A
}

.logo_side{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.games{
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
}

.categoryData{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.allgames{
    font-size: 1.5rem;
    padding: 1rem 2rem 1rem 2rem;
}


.categories{
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
}

.cats{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    /* padding: 2rem; */
    
}

.icon{
    color: red;
}

.category{
    opacity: 0.5;
    /* padding: 1rem; */
    width: 100%;
    padding: 1rem 2rem 1rem 2rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* padding: 0; */
}

.active{
    opacity: 1;
    background-color: #E9223021;

}

.games p{
    font-size: 1.5rem;
    font-weight: bold;
}


.info_tag{
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1rem 2rem 1rem 2rem;
}

.item{
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1rem 2rem 1rem 2rem;
}

.favorite{
    font-size: 1.5rem;
    padding: 1rem 2rem 1rem 2rem;
}

.side_footer{
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    /* width: 70%; */
    display: flex;
    justify-content: center;
    padding: 1rem 2rem 1rem 2rem;
}

.side_footer p{
    width: 80%;
}

hr{
    text-align: center;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self:center ;
}

@media only screen and (max-width:480px){
    .main{
        display: flex;
        justify-content: space-between;
        width: 100%;
        /* width: 37.5rem; */
        color: #2B358A;
        z-index: 99;
    }
}