.terms_container{
    width: 100%;
    min-height: calc(100dvh - 100px);
    /* background: #fff; */
    overflow:auto;
    z-index: 99;
    padding: 0px 8px 15rem 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
}

.navbar{
    width: 100%;
    padding: 12px;
    background: #2B358A;
    border-radius: 4px;
    position: relative;
}

.back_icon{
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 800 !important;
    cursor: pointer;
}

.navbar_title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'Inter',sans-serif;
    font-size: 1.6rem;
    color: #fff;
}

.term{
    width: 100%;
    text-align: left;
    padding: 0 12px 0 12px;
    color: #172A6E;
    font-size: 1.3rem;
    font-family: 'Inter',sans-serif;
}