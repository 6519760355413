.title_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    z-index: 99;
    padding: 1rem 2rem 1rem 2rem;
}

.title{
    color: #2B358A;
    font-size: 3rem;
    font-family: 'Inter',sans-serif;
    font-weight: 700;
}

.number{
    background-color: #E92230;
    width:5rem;
    height: 5rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: white;
    font-weight: bolder;
}

.text{
    padding: 1.5rem;
    width: 90%;
    text-align: center;
    color: #2B358A;
}

.text p{
    font-size: 2rem;
}

.code{
    width: 100%;
    background-color: #2B358A;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    gap: 2rem;
    padding: 1rem;
    /* justify-content: center; */
}

.code .digit{
    background-color: white;
    font-size: 3rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #2B358A;
    font-weight: bold;
    border-radius: 10px;
}

.card{
    text-align: center;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    width: 80%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card p{
    font-size: 1.5rem;
    font-weight: 700;
    color: #2B358A;
    width: 80%;
}

.card button{
    background-color: #E92230;
    height: 4rem;
    border: none;
    border-radius: 10px;
    width: 80%;
    color:white;
    margin-top: 2rem;
}

.carousel{
    padding-left: 2rem;
    padding-top: 1.5rem;
}

.main_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 1rem; */
    /* padding: 0 2rem 1rem 2rem; */
    z-index: 99;
    /* padding-bottom: 10rem; */
}


.icon{
    color: white;
    font-size: 1.5rem;
}


@media only screen and (max-width:576px){
    .main_container{
       
        gap: 1rem;
        /* padding: 0 2rem 1rem 2rem; */
      
    }
}